import Image from "next/image";

import { useState, useEffect } from "react";
import styles from "./BannerImage.module.css";

interface BannerImageProps {
  language: string;
  avatarType: string;
}

const BannerImage = ({ language, avatarType }: BannerImageProps) => {
  const [src, setSrc] = useState<string>("/images/header-ja.png");
  const [imgSize, setImgSize] = useState({
    width: 512,
    height: 512,
  });

  useEffect(() => {
    switch (language) {
      case "ja":
        setSrc("/images/header-ja.png");
        setImgSize({
          width: 512,
          height: 512,
        });
        break;
      case "en":
        if (avatarType === "witness") {
          setSrc("/images/header-witness.png");
          setImgSize({
            width: 970,
            height: 320,
          });
        } else {
          setSrc("/images/header-en.png");
          setImgSize({
            width: 512,
            height: 512,
          });
        }
        break;
      case "zh":
        setSrc("/images/header-zh.png");
        setImgSize({
          width: 512,
          height: 512,
        });
        break;
      case "et":
        if (avatarType === "witness") {
          setSrc("/images/header-witness.png");
          setImgSize({
            width: 970,
            height: 320,
          });
        } else {
          setSrc("/images/header-en.png");
          setImgSize({
            width: 512,
            height: 512,
          });
        }
        break;
      default:
        setSrc("/images/header-en.png");
        setImgSize({
          width: 512,
          height: 512,
        });
    }
  }, [language, avatarType]);

  return (
    <Image
      src={src}
      alt="avatars"
      width={imgSize.width}
      height={imgSize.height}
    />
  );
};

export { BannerImage };
