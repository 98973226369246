import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import i18n from "i18next";
import { Form, Field } from "react-final-form";
import Image from "next/image";

import { SelectInput, NumberInput, TextInput } from "../../ui";
import { BannerImage } from "../";
import styles from "./HomePageComponent.module.css";

export interface HomePageSheetValues {
  randomPattern: number;
  language: string;
  mode: string;
  role: string;
  avatarType: string;
  experimentId: number;
  clientSid: string;
}

export interface HomePageComponentProps {
  onSubmit: (values: HomePageSheetValues) => void;
}

const HomePageComponent = ({ onSubmit }: HomePageComponentProps) => {
  const { t } = useTranslation();
  const initialValues: HomePageSheetValues = {
    randomPattern: 4,
    language: i18n.language,
    mode: ["et", "lt"].includes(i18n.language) ? "op" : "ai",
    role: "interviewer",
    avatarType: i18n.language === "et" ? "witness" : "csa",
    experimentId: 0,
    clientSid: "",
  };
  const language = i18n.language;

  return (
    <div style={{ textAlign: "center" }}>
      <Form
        onSubmit={onSubmit}
        mutators={{
          setMode: ([event, _], state, utils) => {
            // Switch the avatar mode for English version
            if (event.target.id === "avatarType" && language === "en") {
              if (event.target.value === "csa") {
                utils.changeValue(state, "mode", () => "ai");
              } else if (event.target.value === "witness") {
                utils.changeValue(state, "mode", () => "op");
              } else {
                utils.changeValue(state, "mode", () => "ai");
              }
            }
          },
        }}
        initialValues={initialValues}
        render={({ handleSubmit, form, submitting, pristine, values }) => (
          <form onSubmit={handleSubmit} onChange={form.mutators.setMode}>
            <BannerImage language={language} avatarType={values.avatarType} />
            <span
              style={{
                width: "100%",
                paddingBottom: "10px",
                display: "inline-block",
              }}
            />
            <h2>
              {values.avatarType === "witness"
                ? t("startPage:titleHomeWitness")
                : t("startPage:titleHome")}
            </h2>
            <span
              style={{
                width: "100%",
                paddingBottom: "10px",
                display: "inline-block",
              }}
            />
            {language === "fi" && (
              <>
                <Image
                  src={"/images/eu-logo.png"}
                  alt="eu"
                  width={600}
                  height={100}
                />
                <span
                  style={{
                    width: "100%",
                    paddingBottom: "10px",
                    display: "inline-block",
                  }}
                />
              </>
            )}
            {language === "fi" && (
              <>
                <p className={styles.disclaimer}>
                  {t("startPage:euDisclaimer")}
                </p>
                <span
                  style={{
                    width: "100%",
                    paddingBottom: "10px",
                    display: "inline-block",
                  }}
                />
              </>
            )}
            {/* Available language for each combination of avatar type and mode */}
            {/* CSA AI: En, Ja, Fi, Zh */}
            {/* CSA Op: Lt */}
            {/* Witness AI: */}
            {/* Witness Op: Et, En */}
            <div
              className={styles.fieldWrapper}
              hidden={
                (values.avatarType === "csa" && !["lt"].includes(language)) ||
                (values.avatarType === "witness" &&
                  !["et", "en"].includes(language))
              }
            >
              <label className={styles.labelText} htmlFor="role">
                {t("startPage:role")}
              </label>
              <Field<string> id="role" name="role" component={SelectInput}>
                <option value="interviewer">
                  {t("startPage:interviewer")}
                </option>
                {/* Currently operator version is for Witness and CSA of Lt */}
                <option value="operator">{t("startPage:operator")}</option>
              </Field>
            </div>
            {/* Interviewers do not select the mode. */}
            {/* No language of a mode has CSA/Witness switch */}
            <div
              className={styles.fieldWrapper}
              hidden={
                values.role !== "interviewer" ||
                (values.avatarType === "csa" && ![""].includes(language)) ||
                (values.avatarType === "witness" && ![""].includes(language))
              }
            >
              <label className={styles.labelText} htmlFor="mode">
                {t("startPage:mode")}
              </label>
              <Field<string> id="mode" name="mode" component={SelectInput}>
                <option value="ai">{t("startPage:aiMode")}</option>
                <option value="op">{t("startPage:operatorMode")}</option>
              </Field>
            </div>
            {/* Interviewers do not select the mode. */}
            {/* English has CSA/Witness switch */}
            <div
              className={styles.fieldWrapper}
              hidden={
                values.role !== "interviewer" || !["en"].includes(language)
              }
            >
              <label className={styles.labelText} htmlFor="avatarType">
                {t("startPage:avatarType")}
              </label>
              <Field<string>
                id="avatarType"
                name="avatarType"
                component={SelectInput}
              >
                <option
                  value="csa"
                  hidden={!["en", "ja", "fi", "zh", "lt"].includes(language)}
                >
                  {t("startPage:csa")}
                </option>
                <option
                  value="witness"
                  hidden={!["et", "en"].includes(language)}
                >
                  {t("startPage:witness")}
                </option>
                <option value="sue" hidden={![""].includes(language)}>
                  {t("startPage:sue")}
                </option>
              </Field>
            </div>
            {/* Setting pattern of Japanese*/}
            <div
              className={styles.fieldWrapper}
              hidden={
                values.role !== "interviewer" ||
                (values.avatarType === "csa" && !["ja"].includes(language)) ||
                (values.avatarType === "witness" && ![""].includes(language))
              }
            >
              <label className={styles.labelText} htmlFor="randomPattern">
                {t("startPage:randomPattern")}
              </label>
              <Field<string>
                id="randomPattern"
                name="randomPattern"
                component={SelectInput}
              >
                <option value="4">{t("startPage:randomPattern4")}</option>
                <option value="9">{t("startPage:randomPattern9")}</option>
                <option value="7">{t("startPage:randomPattern7")}</option>
                <option value="5">{t("startPage:randomPattern5")}</option>
                <option value="6">{t("startPage:randomPattern6")}</option>
                <option value="1">{t("startPage:randomPattern1")}</option>
                <option value="2">{t("startPage:randomPattern2")}</option>
                <option value="8">{t("startPage:randomPattern8")}</option>
                <option value="3">{t("startPage:randomPattern3")}</option>
              </Field>
            </div>
            {/* Setting pattern of English */}
            <div
              className={styles.fieldWrapper}
              hidden={
                values.role !== "interviewer" ||
                (values.avatarType === "csa" && !["en"].includes(language)) ||
                (values.avatarType === "witness" && ![""].includes(language))
              }
            >
              <label className={styles.labelText} htmlFor="randomPattern">
                {t("startPage:randomPattern")}
              </label>
              <Field<string>
                id="randomPattern"
                name="randomPattern"
                component={SelectInput}
              >
                <option value="1">{t("startPage:randomPattern1")}</option>
                <option value="2">{t("startPage:randomPattern2")}</option>
                <option value="3">{t("startPage:randomPattern3")}</option>
                <option value="4">{t("startPage:randomPattern4")}</option>
                <option value="5">{t("startPage:randomPattern5")}</option>
                <option value="6">{t("startPage:randomPattern6")}</option>
                <option value="7">{t("startPage:randomPattern7")}</option>
                <option value="8">{t("startPage:randomPattern8")}</option>
                <option value="9">{t("startPage:randomPattern9")}</option>
                <option value="10">{t("startPage:randomPattern10")}</option>
                <option value="11">{t("startPage:randomPattern11")}</option>
                <option value="12">{t("startPage:randomPattern12")}</option>
                <option value="13">{t("startPage:randomPattern13")}</option>
                <option value="14">{t("startPage:randomPattern14")}</option>
                <option value="15">{t("startPage:randomPattern15")}</option>
                <option value="16">{t("startPage:randomPattern16")}</option>
              </Field>
            </div>
            {/* Setting pattern of Estonian */}
            <div
              className={styles.fieldWrapper}
              hidden={
                values.role !== "interviewer" ||
                (values.avatarType === "csa" && ![""].includes(language)) ||
                (values.avatarType === "witness" && !["et"].includes(language))
              }
            >
              <label className={styles.labelText} htmlFor="randomPattern">
                {t("startPage:randomPattern")}
              </label>
              <Field<string>
                id="randomPattern"
                name="randomPattern"
                component={SelectInput}
              >
                <option value="1">{t("startPage:randomPattern1")}</option>
                <option value="2">{t("startPage:randomPattern2")}</option>
                <option value="3">{t("startPage:randomPattern3")}</option>
                <option value="4">{t("startPage:randomPattern4")}</option>
                <option value="5">{t("startPage:randomPattern5")}</option>
                <option value="6">{t("startPage:randomPattern6")}</option>
                <option value="7">{t("startPage:randomPattern7")}</option>
                <option value="8">{t("startPage:randomPattern8")}</option>
                <option value="9">{t("startPage:randomPattern9")}</option>
                <option value="10">{t("startPage:randomPattern10")}</option>
                <option value="11">{t("startPage:randomPattern11")}</option>
                <option value="12">{t("startPage:randomPattern12")}</option>
                <option value="13">{t("startPage:randomPattern13")}</option>
                <option value="14">{t("startPage:randomPattern14")}</option>
                <option value="15">{t("startPage:randomPattern15")}</option>
                <option value="16">{t("startPage:randomPattern16")}</option>
              </Field>
            </div>
            {/* Setting pattern of Lithuanian */}
            <div
              className={styles.fieldWrapper}
              hidden={
                values.role !== "interviewer" ||
                (values.avatarType === "csa" && !["lt"].includes(language)) ||
                (values.avatarType === "witness" && ![""].includes(language))
              }
            >
              <label className={styles.labelText} htmlFor="randomPattern">
                {t("startPage:randomPattern")}
              </label>
              <Field<string>
                id="randomPattern"
                name="randomPattern"
                component={SelectInput}
              >
                <option value="1">{t("startPage:randomPattern1")}</option>
                <option value="2">{t("startPage:randomPattern2")}</option>
                <option value="3">{t("startPage:randomPattern3")}</option>
                <option value="4">{t("startPage:randomPattern4")}</option>
                <option value="5">{t("startPage:randomPattern5")}</option>
                <option value="6">{t("startPage:randomPattern6")}</option>
                <option value="7">{t("startPage:randomPattern7")}</option>
                <option value="8">{t("startPage:randomPattern8")}</option>
                <option value="9">{t("startPage:randomPattern9")}</option>
                <option value="10">{t("startPage:randomPattern10")}</option>
                <option value="11">{t("startPage:randomPattern11")}</option>
                <option value="12">{t("startPage:randomPattern12")}</option>
                <option value="13">{t("startPage:randomPattern13")}</option>
                <option value="14">{t("startPage:randomPattern14")}</option>
                <option value="15">{t("startPage:randomPattern15")}</option>
                <option value="16">{t("startPage:randomPattern16")}</option>
              </Field>
            </div>
            <div
              className={styles.fieldWrapper}
              hidden={values.role !== "operator"}
            >
              <label htmlFor="experimentId">
                {t("startPage:experimentId")}
              </label>
              <Field
                id="experimentId"
                name="experimentId"
                component={NumberInput}
              />
            </div>
            <div
              className={styles.fieldWrapper}
              hidden={values.role !== "operator"}
            >
              <label htmlFor="clientSid">{t("startPage:clientSid")}</label>
              <Field id="clientSid" name="clientSid" component={TextInput} />
            </div>
            <div className={styles.fieldWrapper}>
              <Field<string>
                id="language"
                name="language"
                component={SelectInput}
                value={language}
                hidden
              >
                <option value="ja">{t("startPage:ja")}</option>
                <option value="en">{t("startPage:en")}</option>
                <option value="zh">{t("startPage:zh")}</option>
                <option value="lt">{t("startPage:lt")}</option>
                <option value="et">{t("startPage:et")}</option>
              </Field>
            </div>
            <span
              style={{
                width: "100%",
                paddingBottom: "10px",
                display: "inline-block",
              }}
            />
            <div className="buttons">
              <button
                value={"next"}
                className={styles.controlButton}
                type="submit"
                disabled={false}
              >
                {t("common:start")}
              </button>
            </div>
          </form>
        )}
      />
    </div>
  );
};

export { HomePageComponent };
