import { useRouter } from "next/router";
import { HomePageComponent } from "../components/home/HomePageComponent";
import { HomePageSheetValues } from "../components/home/HomePageComponent/HomePageComponent";
import io from "socket.io-client";
import { useTranslation } from "react-i18next";

const Home = () => {
  const router = useRouter();
  const { t } = useTranslation();

  const handleSubmit = (values: HomePageSheetValues) => {
    if (values.role === "operator") {
      // Connect SIO
      const socketHost: string =
        process.env.NEXT_PUBLIC_AVATARTRAINING_AI_API_DOMAIN ??
        "https://localhost:8080";
      const socket = io(socketHost, {
        reconnection: true,
        reconnectionDelay: 10000,
        reconnectionDelayMax: 100000,
        path: "/avatartrainingsio",
        transports: ["websocket"],
      });

      // Check experiment ID and client SID here
      socket.emit(
        "check_join_experiment_as_operator",
        values.experimentId,
        values.clientSid,
        (result: boolean, err: string) => {
          socket.disconnect();
          if (!result) {
            if (err === "ExperimentIDOrSIDWrongError") {
              alert(t("startPage:experimentIDOrSIDWrongError"));
            } else if (err === "OperatorAlreadyExistError") {
              alert(t("startPage:operatorAlreadyExistError"));
            } else {
              alert(t("startPage:failToJoinOperator"));
            }
            return;
          }
          router.push({
            pathname: "/avatar-training",
            query: {
              randomPattern: values.randomPattern,
              language: values.language,
              mode: values.mode,
              avatarType: values.avatarType,
              role: values.role,
              experimentId: values.experimentId,
              clientSid: values.clientSid,
            },
          });
        }
      );
    } else {
      router.push({
        pathname: "/avatar-training",
        query: {
          randomPattern: values.randomPattern,
          language: values.language,
          mode: values.mode,
          avatarType: values.avatarType,
          role: values.role,
          experimentId: values.experimentId,
          clientSid: values.clientSid,
        },
      });
    }
  };

  return <HomePageComponent onSubmit={handleSubmit} />;
};

export default Home;
